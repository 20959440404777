import { Component, OnInit } from '@angular/core';
import { User } from '../shared/models/user.model';
@Component({
  selector: 'payware-vpos-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  // specifies the template string for the barcode generator component
  template: `<ejs-barcodegenerator style="display: block;"  #barcode id="barcode" width="200px" height="150px" mode="SVG" type="Codabar" value="123456789">`
})

export class DetailsComponent implements OnInit {

  buttonHomeShow : boolean;
  buttonNewShow : boolean;
  user: User;
  prData: any;
  amount: number;
  currency: string;
  qrWidth: string;
  qrScale: number;
  qrVersion: number;
  qrErrorCorrectionLevel: string;
  qrElementType: string;
  isOpen: boolean;

  constructor() {

  }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.prData = JSON.parse(sessionStorage.getItem("prData"));
    this.currency = this.prData.code == null ? this.prData.currency : this.prData.code;
    this.amount = +this.prData.amount;
    this.buttonHomeShow = true;
    this.buttonNewShow = true;
    this.qrWidth = "100%";
    this.qrScale = 16;
    this.qrVersion = 3;
    this.qrErrorCorrectionLevel = "Q";
    this.qrElementType = "svg";
    this.isOpen = false;
  }

}
