import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CountdownComponent } from 'ngx-countdown';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { User } from '../shared/models/user.model';
import { TransactionsService } from '../shared/services/transactions.service';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { EMPTY } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as copy from 'copy-to-clipboard';

@Component({
  selector: 'payware-vpos-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit, OnDestroy {

  buttonHomeShow: boolean;
  buttonNewShow: boolean;
  user: User;
  amount: number;
  currencyCode: string;
  ttl: number;
  trData: any;
  imageData: any;
  expired: boolean = false;
  visualizeError: boolean;
  canceled: boolean = false;
  processFlag: boolean = false;
  loading: boolean = undefined;
  shared: boolean = false;
  copied: boolean = false;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  constructor(private sanitizer: DomSanitizer,
    private loginService: LoginService,
    private router: Router,
    private transactionsService: TransactionsService,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute) {
    if (sessionStorage.getItem("user") === null
      && !this.activatedRoute.snapshot.queryParams['vLogin']) {
      this.loginService.logout();
    }
  }

  ngOnInit() {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    const vlogin = queryParams['vLogin'];
    if (vlogin) {
      this.loading = true;
      this.loginService.login(vlogin)
        .pipe(
          mergeMap((user) => {
            const payload = {
              amount: (queryParams['amount'] && queryParams['amount'] > 0)
                ? parseFloat(queryParams['amount']).toFixed(2)
                  : 'Invalid amount',
              ttl: (queryParams['timeToLive'] && Number(queryParams['timeToLive']))
                ? queryParams['timeToLive'] < 120
                  ? '120'
                  : queryParams['timeToLive'] > 600
                    ? '600'
                    : queryParams['timeToLive']
                      : '120',
              currencyCode: queryParams['currency'] ? (queryParams['currency'] || '').toUpperCase() : user.currencyId,
              reasonL1: "webPOS: " + vlogin,
              shop: user.shopCode,
              imageFlag: queryParams['type']
              ? queryParams['type'].toUpperCase() == 'BARCODE'
                ? 'BARCODE'
                  : 'QR'
                    : 'QR',
            }

            sessionStorage.setItem("amount", payload.amount);
            sessionStorage.setItem("currencyCode", payload.currencyCode);
            let expires = +Date.now() + payload.ttl * 1000;
            sessionStorage.setItem("expires", expires.toString());

            return this.transactionsService.generate(payload)
              .pipe(
                tap(trData => sessionStorage.setItem("trData", JSON.stringify(trData)))
              );
          }),
          catchError((err: HttpErrorResponse) => {
            this.loading = false;
            this.visualizeError = true;
            return EMPTY;
          })
        ).subscribe(() => this.showQr());
    } else {
      this.showQr();
    }
  }

  private showQr() {
    this.loading = false;
    this.processFlag = JSON.parse(sessionStorage.getItem("processing"));
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.amount = +sessionStorage.getItem("amount");
    this.currencyCode = sessionStorage.getItem("currencyCode");
    if (+sessionStorage.getItem("expires") < +Date.now()) {
      this.expired = true;
      this.ttl = +sessionStorage.getItem("expires");
    } else {
      this.ttl = (+sessionStorage.getItem("expires") - +Date.now()) / 1000;
    }
    this.trData = JSON.parse(sessionStorage.getItem("trData"));
    this.imageData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64, ` + this.trData.imageData);

    this.buttonHomeShow = true;
    this.buttonNewShow = true;
    this.socket();
  }

  handleEvent($event): void {
    if ($event.left == 0) {
      this.expired = true;
    }
  }

  cancel() {
    this.canceled = true;
    this.transactionsService.cancel(this.trData.transactionId, this.user.loginId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        }),
        tap(prData => sessionStorage.setItem("prData", JSON.stringify(prData)))
      )
      .subscribe(() => this.router.navigate(["/details"], { queryParamsHandling: null }));

  }

  logout() {
    this.loginService.logout();
  }

  copyToClipBoardString() {
    copy(this.trData.transactionId);
    if (this.copied) {
      return;
    }
    this.copied = true;
    setTimeout(() => this.copied = false, 2500)
  }

  share() {
    this.transactionsService.getLink(this.trData.transactionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        })
      )
      .subscribe(() => {
        let shareData = {
          title: 'payware',
          text: this.translate.instant('showResources.shared_payment'),
          url: 'https://url.payware.eu/' + this.trData.transactionId
        }

        const resultPara = document.querySelector('.result');

        navigator.share(shareData)
          .catch((e) =>
            resultPara.textContent = 'Error: ' + e
          );
      });
    copy('https://url.payware.eu/' + this.trData.transactionId);
    if (this.shared) {
      return;
    }
    this.shared = true;
    setTimeout(() => this.shared = false, 2500)
  }

  socket() {
    const socket = new SockJS(environment.BACKEND_API_URL + '/payment-updates');
    const stompClient = Stomp.over(socket);
    stompClient.connect({}, (frame) => {
      stompClient.subscribe(`/topic/payment-finalized/${this.trData.transactionId}`, data => {
        // console.log('Data over websocket = ', data.body);
        sessionStorage.setItem("prData", data.body);
        this.router.navigate(["/details"], { queryParamsHandling: null });
      });
    });
  }

  ngOnDestroy() {
    if (this.canceled) {
      sessionStorage.setItem("expires", "1");
    }
  }
}
