<div class="main-container">
  <div class="content-container">
      <div class="content-area">
          <div class="clr-row">
              <div class="clr-col-12">
                  <div class="card">
                    <payware-vpos-header></payware-vpos-header>
                      <div class="card-block" style="padding-top: 0px;">
                        <h3 style="margin-top: 6px; text-align-last: justify;">{{'showResources.identifier' | translate}}{{trData.transactionId}}</h3>
                        <h3 style="margin-top: 0px; text-align-last: justify;">{{currencyCode}} {{amount.toFixed(2) | number: '1.2-2' : 'en'}}</h3>
                        <div style="margin-bottom: 32px;">
                          <span class="shareIcon floatL">
                            <button type="button" class="btn btn-sm btn-icon alignLeftLabel" *ngIf="!expired && ttl !== 1 && !processFlag" aria-label="cancel" (click)="cancel()">
                              <cds-icon shape="times"></cds-icon> {{'showResources.cancel' | translate}}
                            </button>
                          </span>
                          <span class="floatR" *ngIf="expired && ttl !== 1"><span class="expired">{{'showResources.expired' | translate}}</span></span>
                          <span class="floatR" *ngIf="ttl === 1"><span class="expired">{{'showResources.canceled' | translate}}</span></span>
                          <span class="floatR" *ngIf="!expired && ttl !== 1 && !processFlag">{{'showResources.expiresIn' | translate}}
                          <countdown [config]="{leftTime: ttl}"  (event)="handleEvent($event)"></countdown></span>
                          <span class="floatR" *ngIf="processFlag">{{'showResources.expecting' | translate}}
                            <countdown [config]="{leftTime: ttl}"  (event)="handleEvent($event)"></countdown></span>
                        </div>
                        <div>
                          <label *ngIf="!copied && !shared">&nbsp;</label>
                          <label *ngIf="copied">{{'showResources.copied' | translate}} </label>
                          <label >{{'showResources.copied' | translate}} </label>
                          <label *ngIf="shared">{{'showResources.shared' | translate}}</label>
                        </div>
                        <div>
                          <button type="button" class="btn" aria-label="copy" (click)="copyToClipBoardString()">
                            <cds-icon shape="copy"></cds-icon> {{'showResources.copy' | translate}}
                          </button>
                          <button type="button" class="btn" *ngIf="!expired && ttl !== 1 && !processFlag" aria-label="share" (click)="share()" style="margin-right: 0px;">
                            <cds-icon shape="share"></cds-icon> {{'showResources.share' | translate}}
                          </button>
                          <button type="button" class="btn" *ngIf="expired || ttl === 1 || processFlag" aria-label="share" (click)="share()" style="margin-right: 0px;" disabled>
                            <cds-icon shape="share"></cds-icon> {{'showResources.share' | translate}}
                          </button>
                        </div>
                      </div>
                      <payware-vpos-footer
                        [buttonNewShow]="buttonNewShow"
                        [buttonHomeShow]="buttonHomeShow"
                        ></payware-vpos-footer>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
