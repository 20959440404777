import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, filter, flatMap } from 'rxjs/operators';
import { Account } from '../models/account.model';

@Injectable({
  providedIn: 'root'
})
export class EWalletsService {

  walletsStateStream$: BehaviorSubject<Account[]> = new BehaviorSubject(null);

  constructor(private httpClient: HttpClient) {
    this.getAccounts().subscribe();
  }

  getAccounts(): Observable<Account[]> {
    return this.httpClient.get<Account[]>(environment.BACKEND_API_URL + "/vpos/api/accounts")
      .pipe(tap((wallets: Account[]) => this.walletsStateStream$.next(wallets)));
  }

  getAccountsState() {
    return this.walletsStateStream$.asObservable()
      .pipe(
        filter((wallets: Account[]) => !!wallets)
      );
  }

}
