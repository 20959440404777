import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {

  userStateStream$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  constructor(private http: HttpClient) {
   }

  getUser() {
    return this.http.get<User>(`${environment.BACKEND_API_URL}/vpos/user`)
      .pipe(
        tap((user: User) => this.userStateStream$.next(user)),
        map((user: User) => user)
      );
  }

  getUserState(): Observable<User> {
    return this.userStateStream$.asObservable().pipe(filter((user: User) => !!user));
  }

}
