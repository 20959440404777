import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Currency } from '../models/currency.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private httpClient: HttpClient) { }

  getCurrencies() {
    return this.httpClient.get<Currency[]>(environment.BACKEND_API_URL + "/vpos/api/currencies");
  }

  getSelectedCurrencies(user: User) {
    return this.httpClient.get<Currency[]>(environment.BACKEND_API_URL + "/vpos/api/selected-currencies/" + user.loginId);
  }
}
