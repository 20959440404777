import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  request: any;

  constructor(private httpClient: HttpClient, private translate: TranslateService) { }

  generate(formValues: any) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    this.toJsonGenerate(formValues);
    return this.httpClient.post(environment.BACKEND_API_URL + "/vpos/api/transactions", this.request, { headers });
  }

  toJsonGenerate(formValues: any) {
    this.request = {
      shop: formValues.shop,
      trData: {
        amount: formValues.amount,
        currency: formValues.currencyCode,
        reasonL1: formValues.reasonL1
      },
      trOptions: {
        type: formValues.imageFlag,
        timeToLive: formValues.timeToLive
      }
    };
  }

  process(shopCode: string, formValues: any) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    this.toJsonProcess(shopCode, formValues);
    // console.log(this.request);
    return this.httpClient.post(environment.BACKEND_API_URL + "/vpos/api/transactions/" + formValues.transactionId, this.request, { headers });
  }

  toJsonProcess(shopCode: string, formValues: any) {
    this.request = {
      shop: shopCode,
      trData: {
        amount: formValues.amount,
        currency: formValues.currency,
        reasonL1: formValues.reasonL1,
        reasonL2: formValues.reasonL2
      },
      trOptions: {
        timeToLive: formValues.timeToLive
      }
    }
  }


  getTrData(transactionId: String) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.httpClient.get(environment.BACKEND_API_URL + `/vpos/api/transactions/${transactionId}`, { headers });
  }

  cancel(transactionId: string, loginId: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    this.toJsonCancel(loginId);
    return this.httpClient.patch(environment.BACKEND_API_URL + "/vpos/api/transactions/" + transactionId, this.request, { headers });
  }

  toJsonCancel(loginId: string) {
    this.request = {
      status: "CANCELLED",
      statusMessage: this.translate.instant('showResources.canceledBy') + loginId
    }
  }

  getLink(transactionId: String) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.httpClient.get(environment.BACKEND_API_URL + `/vpos/api/transactions/${transactionId}/link`, { headers, responseType: 'text' });
  }

}
