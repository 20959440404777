import { RouterModule, Routes } from "@angular/router";
import { NumpadComponent } from "./home/numpad/numpad.component";
import { ShowComponent } from "./show/show.component";
import { DetailsComponent } from "./details/details.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { AuthorizationGuardService } from "./shared/services/authorization-guard.service";
import { ScannerComponent } from "./home/scanner/scanner.component";
import { HomeComponent } from "./home/home.component";
import { NfcComponent } from "./home/nfc/nfc.component";
import { NgModule } from "@angular/core";
import { ShareComponent } from "./share/share.component";
import { TextComponent } from "./home/text/text.component";

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'share', component: ShareComponent, canActivate: [AuthorizationGuardService] },
  { path: 'show', component: ShowComponent, canActivate: [AuthorizationGuardService] },
  { path: 'details', component: DetailsComponent, canActivate: [AuthorizationGuardService] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthorizationGuardService] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, children: [
    { path: '', redirectTo: 'numpad', pathMatch: 'full' },
    { path: 'numpad', component: NumpadComponent },
    { path: 'scanner', component: ScannerComponent },
    { path: 'nfc', component: NfcComponent },
    { path: 'text', component: TextComponent } ]
   }
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
