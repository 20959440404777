import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CountdownComponent } from 'ngx-countdown';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import { User } from '../shared/models/user.model';
import { TransactionsService } from '../shared/services/transactions.service';
import * as copy from 'copy-to-clipboard';

@Component({
  selector: 'payware-vpos-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  buttonHomeShow: boolean;
  buttonNewShow: boolean;
  trData: any;
  expired: boolean = false;
  ttl: number;
  processFlag: boolean = false;
  currencyCode: string;
  amount: number;
  user: User;
  canceled: boolean = false;
  visualizeError: boolean;
  copied: boolean = false;
  shared: boolean = false;

  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  constructor(private translate: TranslateService,
    private loginService: LoginService,
    private transactionsService: TransactionsService,
    private router: Router
  ) {
    if (sessionStorage.getItem("user") === null) {
      this.loginService.logout();
    }
  }

  ngOnInit(): void {
    this.processFlag = JSON.parse(sessionStorage.getItem("processing"));
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.amount = +sessionStorage.getItem("amount");
    this.currencyCode = sessionStorage.getItem("currencyCode");
    if (+sessionStorage.getItem("expires") < +Date.now()) {
      this.expired = true;
      this.ttl = +sessionStorage.getItem("expires");
    } else {
      this.ttl = (+sessionStorage.getItem("expires") - +Date.now()) / 1000;
    }
    this.trData = JSON.parse(sessionStorage.getItem("trData"));
    this.buttonHomeShow = true;
    this.buttonNewShow = true;

    this.socket();
  }

  copyToClipBoardString() {
    copy(this.trData.transactionId);
    if (this.copied) {
      return;
    }
    this.copied = true;
    setTimeout(() => this.copied = false, 2500)
  }

  share() {
    this.transactionsService.getLink(this.trData.transactionId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        })
      )
      .subscribe(() => {
        let shareData = {
          title: 'payware',
          text: this.translate.instant('showResources.shared_payment'),
          url: 'https://url.payware.eu/' + this.trData.transactionId
        }

        const resultPara = document.querySelector('.result');

        navigator.share(shareData)
          .catch((e) =>
            resultPara.textContent = 'Error: ' + e
          );
      });
    copy('https://url.payware.eu/' + this.trData.transactionId);
    if (this.shared) {
      return;
    }
    this.shared = true;
    setTimeout(() => this.shared = false, 2500)
  }

  socket() {
    const socket = new SockJS(environment.BACKEND_API_URL + '/payment-updates');
    const stompClient = Stomp.over(socket);
    stompClient.connect({}, (frame) => {
      stompClient.subscribe(`/topic/payment-finalized/${this.trData.transactionId}`, data => {
        // console.log('Data over websocket = ', data.body);
        sessionStorage.setItem("prData", data.body);
        this.router.navigate(["/details"], { queryParamsHandling: null });
      });
    });
  }

  handleEvent($event): void {
    if ($event.left == 0) {
      this.expired = true;
    }
  }

  cancel() {
    this.canceled = true;
    this.transactionsService.cancel(this.trData.transactionId, this.user.loginId)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.visualizeError = true;
          return EMPTY;
        }),
        tap(prData => sessionStorage.setItem("prData", JSON.stringify(prData)))
      )
      .subscribe(() => this.router.navigate(["/details"], { queryParamsHandling: null }));

  }

  logout() {
    this.loginService.logout();
  }

  ngOnDestroy() {
    if (this.canceled) { sessionStorage.setItem("expires", "1"); }
  }

}
